<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      head-title="试卷基本信息"
      @head-cancel="headCancel"
    ></head-layout>
    <form-layout
      ref="formLayout"
      :column="formColumn"
      :dataForm.sync="dataForm"
    ></form-layout>
    <head-layout
      head-title="试题"
    ></head-layout>
    <grid-layout
      ref="gridLayTableTopic"
      :table-options="tableOptions"
      :table-data="tableTopicData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-current-change="tableOnLoad"
      @page-size-change="tableOnLoad"
      @page-refresh-change="tableOnLoad"
    >
    </grid-layout>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import {getEduTree} from "@/api/training/examinationPaper";
import {getLineList} from "@/api/training/edupapertype";
import {getDetail, topicPage} from "@/api/training/edupaper";

import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";


export default {
  name: "edupaperView",
  components: {
    FormLayout,
    HeadLayout,
    GridLayout,
  },
  data() {
    return {
      dataForm: {},
      tableData: [],
      tableTopicData: [],
      saveLoading: false,
      tableLoading: false,
      query: {},
      type: undefined,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
    }
  },
  computed: {
    ...mapGetters(["permission", 'colorName']),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.edupaper_add, true),
        viewBtn: this.vaildData(this.permission.edupaper_view, true),
        delBtn: this.vaildData(this.permission.edupaper_delete, true),
        editBtn: this.vaildData(this.permission.edupaper_edit, true)
      };
    },
    headBtnOptions() {
      return [
        {
          label: this.$t('cip.cmn.btn.cancel'),
          emit: "head-cancel",
          type: "button",
          icon: "",
          btnOptType: 'cancel',
          loading: this.saveLoading,
        }
      ]
    },
    formColumn() {
      return [
        {
          label: "试卷编号",
          prop: "code",
          readonly: true,
          width: 180,
          placeholder: "保存后自动生成",
        },
        {
          label: "试卷名称",
          prop: "paperName",
          maxlength: 100,
          showWordLimit: true,
          readonly: true,
        },
        // {
        //   label: "考试级别",
        //   prop: "lexaminationLevel",
        //   type: 'select',
        //   dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=EDUCATION_EXAMINATION_LEVEL',
        //   props: {
        //     label: 'dictValue',
        //     value: 'dictKey',
        //   },
        //   disabled: true,
        // },
        // {
        //   label: "出题依据",
        //   prop: "accordingTo",
        //   type: 'select',
        //   dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=EDUCATION_ACCORDING_TO',
        //   props: {
        //     label: 'dictValue',
        //     value: 'dictKey',
        //   },
        //   disabled: true,
        // },
        {
          label: "专业分类",
          prop: "subjectType",
          dicData: [],
          type: "tree",
          props: {
            label: "title",
            value: "value",
          },
          disabled: true,
        },
        {
          label: "答题时长(分钟)",
          prop: "answerTime",
          readonly: true,
        },
        {
          label: "合格分",
          prop: "qualifiedScore",
          readonly: true,
        },
        {
          label: "总分值",
          prop: "totalScore",
          readonly: true,
        },
        {
          label: "试卷状态",
          prop: "paperStatus",
          type: 'select',
          dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=paper_status',
          props: {
            label: 'dictValue',
            value: 'dictKey',
          },
          value: '0',
          disabled: true,
        },
        // {
        //   label: "是否公布答案",
        //   prop: "openAnswer",
        //   type: 'radio',
        //   dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=YES_NO',
        //   props: {
        //     label: 'dictValue',
        //     value: 'dictKey',
        //   },
        //   value: 'n',
        //   disabled: true,
        // },
        {
          label: "试卷说明",
          prop: "paperExplain",
          type: "textarea",
          overHidden: true,
          span: 24,
          minRows: "2",
          maxRows: "4",
          maxlength: 100,
          showWordLimit: true,
          readonly: true,
        },
      ]
    },
    tableOptions() {
      return {
        menu: false,
        selection: false,
        column: [
          {
            label: "试卷编号",
            prop: "paperId",
            cell: false,
            sortable: true,
            type: 'select',
            hide: true,
          },
          {
            label: "试题编号",
            prop: "topicCode",
            cell: false,
            sortable: true,
            type: 'select',
            width: 200,
            rules: [{
              required: true,
              message: "请输入试题类型",
              trigger: ["blur", "change"]
            }]
          },
          {
            label: "试题名称",
            prop: "topicName",
            cell: false,
            sortable: true,
          },
          {
            label: "题目类型",
            prop: "topicType",
            dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=EDUCATION_TOPIC_TYPE',
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            width: 100,
            overHidden: true,
            align: 'center',
          },
          {
            label: "分值",
            prop: "score",
            type: "number",
            cell: true,
            sortable: true,
            align: 'center',
            width: 100,
            change: (row) => {
              this.calculate(row);
            },
            rules: [{
              required: true,
              message: "请输入单题分值",
              trigger: ["blur", "change"]
            }, {
              pattern: /^(?:[1-9]\d{0,2}|0)$/,
              message: "请输入三位以内的正整数",
            }]
          },
        ],
      }
    },
  },
  methods: {
    initData() {

      getEduTree().then(res => {
        const column = this.findObject(this.$refs.formLayout.option.column, 'subjectType')
        column.dicData = res.data.data
        const column1 = this.findObject(this.$refs.gridLayOut.option.column, 'subjectType')
        column1.dicData = res.data.data
      })
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    formData(id) {
      getDetail(id).then((res) => {
        this.$refs.formLayout.dataForm = res.data.data;
      });
    },
    tableOnLoad(page, params = {}) {
      this.tableLoading = true;
      this.page = page;
      topicPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        this.tableTopicData = data.records;
        this.tableLoading = false;
        this.tableTopicData.selectionClear();
      });
    },
  },
  mounted() {
    this.initData()
  },
  created() {
    if (this.$route.query.row) {
      let row = JSON.parse(this.$route.query.row);
      this.dataForm.id = row.id;
    }
    this.type = this.$route.query.type;
    if (this.type == "add") {
      this.dataForm.id = "";
    } else if (this.type == "edit") {
      this.query.paperId = this.dataForm.id;
      this.formData(this.dataForm.id);
      // this.onLoad(this.page)
      this.tableOnLoad(this.page)
    } else if (this.type == "view") {
      this.query.paperId = this.dataForm.id;
      this.formData(this.dataForm.id);
      // this.onLoad(this.page)
      this.tableOnLoad(this.page)
    }
  }
}
</script>

<style scoped>

</style>
